/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

$body-bg: black;    // body background is black
$body-color: white; // body text is white
$min-contrast-ratio: 3;
$primary: #0590bb;
$secondary: #503070;
$dark: black;
$gradient: linear-gradient(180deg, rgba($secondary, .0), rgba($secondary, .3));

$form-file-button-bg: $primary;

@import "node_modules/bootstrap/scss/bootstrap";
